import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import BootstrapVue from 'bootstrap-vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BootstrapVue
Vue.use(BootstrapVue)

// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// VueGoodTable
import VueGoodTablePlugin from 'vue-good-table';
import '@core/scss/vue/libs/vue-good-table.scss'

Vue.use(VueGoodTablePlugin)

// VueMeta
import VueMeta from 'vue-meta'

// Dayjs
import Dayjs from 'vue-dayjs'

Vue.use(Dayjs)

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')