import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        pageTitle: 'Home'
      },
    },
    {
      path: '/bets',
      name: 'bets',
      component: () => import('@/views/bets/BetsList.vue'),
      meta: {
        pageTitle: 'Bets'
      },
    },
    {
      path: '/game/:id',
      name: 'game',
      component: () => import('@/views/home/Game.vue'),
      meta: {
        pageTitle: 'Game Info'
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.afterEach(() => {
  store.state.appConfig.breadcrumb = undefined
})

export default router